import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Embedpage() {
    const { id } = useParams();
    const isIframe = window.self !== window.top;

    useEffect(() => {
        if (isIframe) {
            // The page is inside an iframe
            axios.get(`https://e.moview.co.in/${id}`)
                .then((response) => {
                    console.log(response.data);
                    window.location.href = response.data.result;
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            // The page is not inside an iframe
            console.log('This page is not loaded inside an iframe.');
        }
    }, [id, isIframe]);

    return (
        <div className='w-screen h-screen bg-black flex items-center justify-center'>
            {isIframe ? (
                <p>Loading...</p>
            ) : (
                <p className='text-red-500 text-5xl font-bold'>Access Denied.This page is not loaded inside an iframe.</p>
            )}
        </div>
    );
}

export default Embedpage;
