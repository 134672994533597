import React from 'react'
import Search from '../components/search'

function Homepage() {
  return (
    <main className="flex min-h-screen flex-col text-white p-2">
      <div className="bg-slate-600 p-4 flex flex-col items-center rounded-2xl">
        <Search />
      </div>
    </main>
  )
}

export default Homepage
