import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
const baseURL = "https://aniwatch-api-opal.vercel.app";

function Search() {
  const [data, setData] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [isopen, setIsopen] = useState(false);
  const [server, setServer] = useState([]);
  const [url, setUrl] = useState([]);
  const getSearch = async (value) => {
    const response = await axios.get(`${baseURL}/anime/search?q=${value}`);
    console.log(response.data);
    setData(response.data);
  };

  const getepisodes = async (value) => {
    const response = await axios.get(`${baseURL}/anime/episodes/${value}`);
    console.log(response.data);
    setEpisodes(response.data);
  };

  const getserver = async (value) => {
    const response = await axios.get(
      `${baseURL}/anime/servers?episodeId=${value}`
    );
    console.log(response.data);
    setServer(response.data);
  };

  const geturl = async (id, category, server) => {
    // console.log(`${baseURL}/anime/episode-srcs?id=${id}&server=${server}&category=${category}`);
    // const response = await axios.get(`${baseURL}/anime/episode-srcs?id=${id}&server=${server}&category=${category}`);
    // console.log(response.data);
    setUrl(
      `https://anime-embeds.vercel.app/watch?id=${id}&server=${server}&category=${category}`
    );
  };

  return (
    <>
      <form className="flex items-center bg-slate-800 w-fit pl-3 rounded-full overflow-hidden text-white">
        <input
          type="text"
          className="outline-none bg-transparent min-w-[300px] py-2"
          onChange={(e) => getSearch(e.target.value)}
        />
        <button className="bg-gradient-o-tr from-blue-400 to flex items-center w-10 aspect-square">
          <FontAwesomeIcon icon={faSearch} className="w-full" />
        </button>
      </form>
      <div className="text-white w-full flex flex-wrap justify-center">
        {data.animes &&
          data.animes.map((item) => (
            <div
              key={item.id}
              className="w-[180px] overflow-hidden p-4 rounded-2xl cursor-pointer"
              onClick={() => {
                getepisodes(item.id);
                setIsopen(true);
              }}
            >
              <img
                src={item.poster}
                alt=""
                className="w-full aspect-[9/14] object-cover"
              />
              <h1 className="line-clamp-1">{item.name}</h1>
            </div>
          ))}
      </div>
      <div
        className={`fixed top-0 left-0 right-0 bg-black w-full h-screen text-white ${
          isopen ? "block" : "hidden"
        } flex`}
      >
        <div className="w-[25%] h-screen overflow-auto text-left">
          {episodes.episodes &&
            episodes.episodes.map((item, index) => (
              <div
                key={index}
                onClick={() => getserver(item.episodeId)}
                className={`${
                  index % 2 == 0 ? "bg-slate-800" : ""
                } py-2 px-4 cursor-pointer`}
              >
                {index + 1} . {item.title}
              </div>
            ))}
        </div>
        <div className="w-[75%] px-4 pt-5">
          <code className="bg-slate-700 py-2 px-4 rounded-md text-lg">&lt;iframe src=&quot;{url}&quot; width=&quot;100%&quot; &gt;&lt;/iframe&gt;</code>
          <iframe
            src={url}
            frameBorder="0"
            className="w-full aspect-video"
          ></iframe>
          <div className="flex gap-2">
            SUB -
            <div className="text-white flex gap-2">
              {server.sub &&
                server.sub.map((item, index) => (
                  <div
                    className=" bg-slate-700 py-1 px-3"
                    key={index}
                    onClick={() =>
                      geturl(server.episodeId, "sub", item.serverName)
                    }
                  >
                    {item.serverName}
                  </div>
                ))}
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            DUB -
            <div className="text-white flex gap-2">
              {server.dub &&
                server.dub.map((item, index) => (
                  <div
                    className=" bg-slate-700 py-1 px-3"
                    key={index}
                    onClick={() =>
                      geturl(server.episodeId, "dub", item.serverName)
                    }
                  >
                    {item.serverName}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
