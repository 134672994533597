import React, { useEffect } from "react";
import axios from "axios";
import "../assets/codenine_skin.css"; 
import "../assets/player_style.css"; 
import "../assets/netflix_skin.css"; 

const BaseUrl = process.env.REACT_APP_BASE_URL;

function Watchpage({  }) {
    const searchParams = new URLSearchParams(window.location.search);

    // Extract specific query parameters
    const idParam = searchParams.get('id');
    const categoryParam = searchParams.get('category');
    const serverParam = searchParams.get('server');

  const init = (data) => {
    const url = data.sources[0].url;
    console.log(url);

    if (window.jwplayer) {
      window.jwplayer.key = "64HPbvSQorQcd52B8XFuhMtEoitbvY/EXJmMBfKcXZQU2Rnn";
      window.jwplayer("codenine_player").setup({
        width: "100%",
        height: "100%",
        file: url,
        autostart: true,
        mute: true,
        stretching: "uniform",
        aspectratio: "16:9",
        playbackRateControls: true,
        sharing: {
          sites: ["facebook", "twitter", "email", "linkedin", "pinterest"],
        },
        advertising: {
          client: "vast",
          schedule: {
            adbreak1: {
              offset: "pre",
              tag: "#",
              skipoffset: 10,
            },
            adbreak2: {
              offset: "10%",
              tag: "#",
              type: "nonlinear",
            },
          },
        },
        logo: {
          file: "#",
          position: "top-left",
          link: "#",
        },
        tracks: data.tracks,
        skin: {
          name: "netflix",
        },
        hlsjsdefault: true,
      });
    }
  };

  const getSrc = () => {
    axios
      .get(`https://aniwatch-api-opal.vercel.app/anime/episode-srcs?id=${idParam}&server=${serverParam}&category=${categoryParam}`)
      .then((response) => {
        init(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Function to dynamically load a script before React continues
    const loadBlockingScript = (src, onLoad) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = false; // This ensures the script is blocking
      script.onload = onLoad;
      document.head.appendChild(script); // Append to head to load it as early as possible
    };

    // Load external scripts in order
    loadBlockingScript("https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js", () => {
      loadBlockingScript("/assets/hls.light.min.js", () => {
        loadBlockingScript("/assets/provider.hlsjs.js", () => {
          loadBlockingScript("https://ssl.p.jwpcdn.com/player/v/8.8.6/jwplayer.js", getSrc);
        });
      });
    });

    // Cleanup: Remove scripts when component unmounts
    return () => {
      const scripts = [
        "https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js",
        "/assets/hls.light.min.js",
        "/assets/provider.hlsjs.js",
        "https://ssl.p.jwpcdn.com/player/v/8.8.6/jwplayer.js",
      ];
      scripts.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) document.head.removeChild(script);
      });
    };
  }, []);

  return (
    <main className="min-w-screen max-w-screen min-h-screen max-h-screen overflow-hidden">
      <div id="codenine_player"></div>
      <style jsx>{`
    #codenine_player {
      width: 100vw !important;
      height: 100vh !important;
      max-height: 100vh !important;
      overflow: hidden !important;
    }
      
  `}</style>
    </main>
  );
}

export default Watchpage;
